import React from "react";
import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import SearchHome from "../../../components/public/others/search/SearchHome";
import Buttons from "../../../components/public/buttons/ButtonHome";
import Footer from "../../../components/public/others/Footer";
import buttonsNavbar from "../../../components/jsons/jsonButtons";
import buttonsNavbarCM from "../../../components/jsons/jsonButtonsCM";
import { TipoPortal } from "../../../services/variables";
import SubTitle from "../../../components/public/others/title/SubTitle";
import OrganogramaCard from "../../../components/public/others/organograma/index";
import SearchHomeDois from "../../../components/public/others/search/SearchHome copy";

export default function Organograma() {
  return (
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
      {TipoPortal == 3 ? (
        <>
          <NavMenu />
          <Buttons names={buttonsNavbar}></Buttons>
          <SearchHomeDois />
          <div className="section-title rotes_pages">
            <h6>
              {" "}
              Início / Informações Institucionais / Estrutura Organizacional
            </h6>
          </div>
          <SubTitle subTitle={"Organograma"} border={true} />
          <OrganogramaCard />
          <Footer />
        </>
      ) : (
        <>
          <NavMenuCM />
          <div className="spaceDivCM"></div>
          <div className="GroupBottomCM">
            <Buttons names={buttonsNavbarCM}></Buttons>
            <div className="section-title rotes_pages">
              <h6>
                {" "}
                Início / Informações Institucionais / Estrutura Organizacional
              </h6>
            </div>
            <SubTitle subTitle={"Organograma"} border={true} />
            <OrganogramaCard />
            <Footer />
          </div>
        </>
      )}
    </>
  );
}
