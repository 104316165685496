import React from "react";
import CarouselIMG from "./CarouselBanner";
import api from "../../../services/api";
import Loading from "../others/Loading";

export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerImg: [],
      removeLoading: false
    };
  }

  componentDidMount() {
    api.get('/banner/show').then((res) => {
      const result = res.data;
      if (!result.err) {
        this.setState({ removeLoading: true });
        
        const updatedBanners = result.res.map(banner => {
          if (banner.IMG === "61bc50bfd4d38ca6ca9bbd651ec88dbfc2be21fa_22f6fc15-751a-4f99-bb85-976fdbd708dd.png") {
            return { ...banner, LINK: "http://sipweb2.adtrcloud.com.br:8110/sipweb/trabalhador/login/login.xhtml" };
          }
          return banner;
        });

        this.setState({ bannerImg: updatedBanners });
      }
    }).catch((err) => {
      this.setState({ removeLoading: true });
    });
  }

  render() {
    return (
      <>
        {!this.state.removeLoading && <Loading />}
        {this.state.bannerImg !== undefined && (
          <>
            <CarouselIMG objImg={this.state.bannerImg} />
          </>
        )}
      </>
    );
  }
}
